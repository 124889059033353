<template>
	<div
		id="media-grid"
		name="media-grid"
		class="row animated fadeIn"
		header-bg-variant="dark"
		header-text-variant="white h4">
		<b-card-body>
			<form
				@submit.prevent="preparePayload"
				@keydown="clearError($event.target.name)"
				@change="clearError($event.target.name)">
				<b-row>
					<b-col>
						<b-form-group
							:errors="errors['name']"
							label-for="name">
							<slot name="label">
								<label>
									{{ translate('name') }} <span class="text-danger">*</span>
								</label>
							</slot>
							<b-form-input
								id="name"
								v-model="form.name"
								:placeholder="translate('name')"
								:class="hasError('name') ? 'is-invalid' : ''"
								name="name"
								type="text" />
							<template v-if="hasError('name')">
								<span
									v-for="error in errors['name']"
									:key="error"
									class="custom-invalid-feedback animated fadeIn"
									v-text="error" />
							</template>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<b-form-group
							:errors="errors['short_name']"
							label-for="short_name">
							<slot name="label">
								<label>
									{{ translate('short_name') }} <span class="text-danger">*</span>
								</label>
							</slot>
							<b-form-input
								id="short_name"
								v-model="form.short_name"
								:placeholder="translate('short_name')"
								:class="hasError('short_name') ? 'is-invalid' : ''"
								name="short_name"
								type="text" />
							<b-form-text id="short_name">
								{{ translate('short_name_description') }}
							</b-form-text>
							<template v-if="hasError('short_name')">
								<span
									v-for="error in errors['short_name']"
									:key="error"
									class="custom-invalid-feedback animated fadeIn"
									v-text="error" />
							</template>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<b-form-group id="formSelector">
							<slot name="tags">
								<label>
									{{ translate('tags') }} <span class="text-danger">*</span>
								</label>
							</slot>
							<multiselect
								id="tags"
								v-model="form.tags"
								:options="form.tags"
								:multiple="true"
								:taggable="true"
								:close-on-select="false"
								:clear-on-select="false"
								:preserve-search="true"
								:preselect-first="false"
								:placeholder="translate('tags_placeholder')"
								name="tags"
								@tag="addTag" />
							<b-form-text class="text-muted">
								{{ translate('tags_disclaimer') }}
							</b-form-text>
							<template v-if="hasError('tags')">
								<span
									v-for="error in errors['tags']"
									:key="error"
									class="custom-invalid-feedback animated fadeIn"
									v-text="error" />
							</template>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<b-form-group id="formSelector">
							<slot name="company_types">
								<label>
									{{ translate('company_type') }}
								</label>
							</slot>
							<multiselect
								id="company_types"
								v-model="form.company_types"
								:options="companyTypes"
								:multiple="true"
								:close-on-select="false"
								:clear-on-select="false"
								:preserve-search="true"
								:preselect-first="false"
								:placeholder="translate('select_company_type')"
								label="text"
								:custom-label="(option, key) => fixDiacriticCharacters(option[key])"
								track-by="value"
								name="company_types">
								<template slot="selection" />
								<template #option="{option}">
									{{ option.text }}
								</template>
							</multiselect>
							<small class="text-muted">
								{{ translate('company_types_disclaimer') }}
							</small>
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group
							:errors="errors['category']"
							label-for="category">
							<slot name="label">
								<label>
									{{ translate('category') }} <span class="text-danger">*</span>
								</label>
							</slot>
							<b-form-select
								id="category"
								v-model="form.category"
								:options="categories"
								:class="hasError('category') ? 'is-invalid' : ''"
								name="category"
								value-field="value"
								text-field="text" />
							<template v-if="hasError('category')">
								<span
									v-for="error in errors['category']"
									:key="error"
									class="custom-invalid-feedback animated fadeIn"
									v-text="error" />
							</template>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<b-form-group
							:errors="errors['thumbnail']"
							class="media-files"
							label-for="thumbnail">
							<slot name="label">
								<label>
									{{ translate('thumbnail') }} <span class="text-danger">*</span>
								</label>
							</slot>
							<b-form-file
								id="thumbnail"
								ref="thumbnail"
								:class="hasError('thumbnail') ? 'is-invalid' : ''"
								:placeholder="translate('no_file')"
								:browse-text="translate('browse')"
								class="text-truncate"
								name="thumbnail"
								type="file" />
							<b-form-text id="thumbnail">
								{{ translate('max', {size: '10'}) }} {{ translate('allowed_ext', {ext: IMAGES_EXT }) }}
							</b-form-text>
							<template v-if="hasError('thumbnail')">
								<span
									v-for="error in errors['thumbnail']"
									:key="error"
									class="custom-invalid-feedback animated fadeIn"
									v-text="error" />
							</template>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<b-form-group
							:errors="errors['mobile_thumbnail']"
							class="media-files"
							label-for="mobile_thumbnail">
							<slot name="label">
								<label>
									{{ translate('mobile_thumbnail') }} <span class="text-danger">*</span>
								</label>
							</slot>
							<b-form-file
								id="mobile_thumbnail"
								ref="mobile_thumbnail"
								:class="hasError('mobile_thumbnail') ? 'is-invalid' : ''"
								:placeholder="translate('no_file')"
								:browse-text="translate('browse')"
								class="text-truncate"
								name="mobile_thumbnail"
								type="file" />
							<b-form-text id="mobile_thumbnail">
								{{ translate('max', {size: '10'}) }} {{ translate('allowed_ext', {ext: IMAGES_EXT }) }}
							</b-form-text>
							<template v-if="hasError('mobile_thumbnail')">
								<span
									v-for="error in errors['mobile_thumbnail']"
									:key="error"
									class="custom-invalid-feedback animated fadeIn"
									v-text="error" />
							</template>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<b-form-group
							:errors="errors['language']"
							label-for="language">
							<slot name="label">
								<label>
									{{ translate('language') }} <span class="text-danger">*</span>
								</label>
							</slot>
							<b-form-select
								id="language"
								v-model="form.language"
								:class="hasError('language') ? 'is-invalid' : ''"
								type="text"
								class="form-control"
								@change="changeLanguage($event)">
								<template slot="first">
									<option
										v-for="language in languages"
										:key="language.text"
										:value="language.value">
										{{ translate(language.text.toLowerCase()) }}
									</option>
								</template>
							</b-form-select>
							<template v-if="hasError('language')">
								<span
									v-for="error in errors['language']"
									:key="error"
									class="custom-invalid-feedback animated fadeIn"
									v-text="error" />
							</template>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row v-if="isAdminOrCorporateWithCountry">
					<b-col>
						<b-form-group id="formSelector">
							<slot name="countries">
								<label>
									{{ translate('country') }}
								</label>
							</slot>
							<multiselect
								id="countries"
								v-model="form.selector"
								:options="countries"
								:multiple="true"
								:close-on-select="false"
								:clear-on-select="false"
								:preserve-search="true"
								:preselect-first="false"
								:placeholder="translate('country_placeholder')"
								label="text"
								:custom-label="(option, key) => fixDiacriticCharacters(option[key])"
								track-by="value"
								name="countries">
								<template slot="selection" />
								<template #option="{option}">
									{{ option.text }}
								</template>
							</multiselect>
							<small class="text-muted">
								{{ translate('country_disclaimer') }}
							</small>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<b-form-group
							:errors="errors['type']"
							label-for="type">
							<slot name="label">
								<label>
									{{ translate('type') }} <span class="text-danger">*</span>
								</label>
							</slot>
							<b-form-select
								id="type"
								v-model="form.type"
								:class="hasError('type') ? 'is-invalid' : ''"
								type="text"
								class="form-control"
								@change="typeChange">
								<template slot="first">
									<option
										v-for="type in types"
										:key="type.text"
										:value="type.value">
										{{ translate(type.value.toLowerCase()) }}
									</option>
								</template>
							</b-form-select>
							<template v-if="hasError('type')">
								<span
									v-for="error in errors['type']"
									:key="error"
									class="custom-invalid-feedback animated fadeIn"
									v-text="error" />
							</template>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col v-if="form.type === 'file'">
						<b-form-group
							:errors="errors['file']"
							class="media-files"
							label-for="file">
							<slot name="label">
								<label>
									{{ translate('file') }} <span class="text-danger">*</span>
								</label>
							</slot>
							<b-form-file
								id="file"
								ref="file"
								:placeholder="translate('no_file')"
								:browse-text="translate('browse')"
								:class="hasError('file') ? 'is-invalid' : ''"
								class="text-truncate"
								name="file"
								type="file" />
							<b-form-text id="file">
								{{ translate('max', {size: '100'}) }}{{ translate('allowed_ext', {ext: ALL_EXT }) }}
							</b-form-text>
							<template v-if="hasError('file')">
								<span
									v-for="error in errors['file']"
									:key="error"
									class="custom-invalid-feedback animated fadeIn"
									v-text="error" />
							</template>
						</b-form-group>
					</b-col>
					<b-col v-else>
						<b-form-group
							:errors="errors['url']"
							label-for="url">
							<slot name="label">
								<label>
									{{ translate('url') }} <span class="text-danger">*</span>
								</label>
							</slot>
							<b-form-input
								id="url"
								v-model="form.url"
								:placeholder="translate('url')"
								:class="hasError('url') ? 'is-invalid' : ''"
								name="url"
								type="text" />
							<template v-if="hasError('url')">
								<span
									v-for="error in errors['url']"
									:key="error"
									class="custom-invalid-feedback animated fadeIn"
									v-text="error" />
							</template>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<b-form-group>
							<slot name="start_date">
								<label>
									{{ translate('start_date') }}
								</label>
							</slot>
							<div
								:class="hasError('start_date') ? 'is-invalid' : ''"
								:style="'height: auto'"
								class="form-control border-0">
								<div class="row custom-gutter">
									<div class="col-xl-2 col-sm-4">
										<select
											id="selectedStartYear"
											v-model="selectedStartYear"
											type="text"
											class="form-control w-100 my-1"
											name="year"
											@change="updateDays('start')">
											<option
												value=""
												selected
												disabled>
												{{ translate('year') }}
											</option>
											<option
												v-for="year in years"
												:key="year"
												:value="year">
												{{ year }}
											</option>
										</select>
									</div>
									<div class="col-xl-2 col-sm-4">
										<select
											id="selectedStartMonth"
											v-model="selectedStartMonth"
											type="text"
											class="form-control w-100 my-1"
											name="month"
											@change="updateDays('start')">
											<option
												value=""
												selected
												disabled>
												{{ translate('month') }}
											</option>
											<option
												v-for="month in months"
												:key="month"
												:value="month">
												{{ month }}
											</option>
										</select>
									</div>
									<div class="col-xl-2 col-sm-4">
										<select
											id="selectedStartDay"
											v-model="selectedStartDay"
											type="text"
											class="form-control w-100 my-1"
											name="day">
											<option
												value=""
												selected
												disabled>
												{{ translate('day') }}
											</option>
											<option
												v-for="day in startDays"
												:key="day"
												:value="day">
												{{ day }}
											</option>
										</select>
									</div>
									<div class="col-xl-2 col-md-3 col-sm-4">
										<select
											id="selectedStartHour"
											v-model="selectedStartHour"
											type="text"
											class="form-control w-100 my-1"
											name="hour">
											<option
												value=""
												selected
												disabled>
												{{ translate('hour') }}
											</option>
											<option
												v-for="hour in hours"
												:key="hour"
												:value="hour">
												{{ hour }}
											</option>
										</select>
									</div>
									<div class="col-xl-2 col-md-3 col-sm-4">
										<select
											id="selectedStartMinute"
											v-model="selectedStartMinute"
											type="text"
											class="form-control w-100 my-1"
											name="minute">
											<option
												value=""
												selected
												disabled>
												{{ translate('minute') }}
											</option>
											<option
												v-for="minute in minutes"
												:key="minute"
												:value="minute">
												{{ minute }}
											</option>
										</select>
									</div>
									<div
										v-if="form.start_date"
										class="alert alert-light col-12 col-md-6 col-lg-12 d-inline my-1 py-0 px-2 font-italic"
										role="alert">
										{{ form.start_date }}
									</div>
									<template v-if="hasError('start_date')">
										<span
											v-for="error in errors['start_date']"
											:key="error"
											class="custom-invalid-feedback animated fadeIn px-2"
											v-text="error" />
									</template>
								</div>
							</div>
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group>
							<slot name="end_date">
								<label>
									{{ translate('end_date') }}
								</label>
							</slot>
							<div
								:class="hasError('end_date') ? 'is-invalid' : ''"
								:style="'height: auto'"
								class="form-control border-0">
								<div class="row custom-gutter">
									<div class="col-xl-2 col-sm-4">
										<select
											id="selectedEndYear"
											v-model="selectedEndYear"
											type="text"
											class="form-control w-100 my-1"
											name="year"
											@change="updateDays('end')">
											<option
												value=""
												selected>
												{{ translate('year') }}
											</option>
											<option
												v-for="year in years"
												:key="year"
												:value="year">
												{{ year }}
											</option>
										</select>
									</div>
									<div class="col-xl-2 col-sm-4">
										<select
											id="selectedEndMonth"
											v-model="selectedEndMonth"
											type="text"
											class="form-control w-100 my-1"
											name="month"
											@change="updateDays('end')">
											<option
												value=""
												selected>
												{{ translate('month') }}
											</option>
											<option
												v-for="month in months"
												:key="month"
												:value="month">
												{{ month }}
											</option>
										</select>
									</div>
									<div class="col-xl-2 col-sm-4">
										<select
											id="selectedEndDay"
											v-model="selectedEndDay"
											type="text"
											class="form-control w-100 my-1"
											name="day">
											<option
												value=""
												selected>
												{{ translate('day') }}
											</option>
											<option
												v-for="day in endDays"
												:key="day"
												:value="day">
												{{ day }}
											</option>
										</select>
									</div>
									<div class="col-xl-2 col-md-3 col-sm-4 d-inline">
										<select
											id="selectedEndHour"
											v-model="selectedEndHour"
											type="text"
											class="form-control w-100 my-1"
											name="hour">
											<option
												value=""
												selected>
												{{ translate('hour') }}
											</option>
											<option
												v-for="hour in hours"
												:key="hour"
												:value="hour">
												{{ hour }}
											</option>
										</select>
									</div>
									<div class="col-xl-2 col-md-3 col-sm-4 d-inline">
										<select
											id="selectedEndMinute"
											v-model="selectedEndMinute"
											type="text"
											class="form-control w-100 my-1"
											name="minute">
											<option
												value=""
												selected>
												{{ translate('minute') }}
											</option>
											<option
												v-for="minute in minutes"
												:key="minute"
												:value="minute">
												{{ minute }}
											</option>
										</select>
									</div>
									<div
										v-if="form.end_date"
										class="alert alert-light col-12 col-md-6 col-lg-12 d-inline my-1 py-0 px-2 font-italic"
										role="alert">
										{{ form.end_date }}
									</div>
									<template v-if="hasError('end_date')">
										<span
											v-for="error in errors['end_date']"
											:key="error"
											class="custom-invalid-feedback animated fadeIn px-2"
											v-text="error" />
									</template>
								</div>
							</div>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row class="mt-4 float-right">
					<b-col>
						<b-button
							:disabled="Object.keys(errors).length > 0 || loading"
							variant="primary"
							size="md"
							class="mr-2"
							@click="preparePayload">
							<template v-if="loading">
								<i class="fa fa-fw fa-spinner fa-pulse" />
								{{ translate('processing') }}
							</template>
							<template v-else>
								{{ translate('submit') }}
							</template>
						</b-button>
					</b-col>
				</b-row>
			</form>
		</b-card-body>
	</div>
</template>
<script>
import Multiselect from 'vue-multiselect';
import {
	MediaLibrary as mediaTranslations,
	Languages as languages,
	Countries as countriesTranslations,
	Time as timeTranslations,
} from '@/translations';
import MediaLibrary from '@/util/MediaLibrary';
import Alert from '@/util/Alert';
import {
	IMAGES_EXT, ALL_EXT, CATEGORIES, LANGUAGES, COMPANY_TYPES as validCompanyTypes,
} from '@/settings/Media';
import { VALID_COUNTRIES as validCountries } from '@/settings/Country';
import { superAdmin, corporate } from '@/settings/Roles';
import { YMDHMS_FORMAT, YM_FORMAT } from '@/settings/Dates';
import { TIMEZONE_NAME } from '@/settings/General';
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
	name: 'NewMediaItem',
	messages: [
		mediaTranslations,
		languages,
		countriesTranslations,
		timeTranslations,
	],
	components: { Multiselect },
	data() {
		return {
			superAdmin,
			corporate,
			form: new this.$Form({
				name: '',
				short_name: '',
				category: '',
				thumbnail: '',
				mobile_thumbnail: '',
				file: '',
				url: '',
				type: 'url',
				language: process.env.VUE_APP_LANG,
				selector: [],
				company_types: [],
				start_date: '',
				end_date: '',
				tags: [],
			}),
			types: [],
			languages: [],
			countries: [],
			countriesOnly: [],
			companyTypes: [],
			companyTypesOnly: [],
			createMediaLibrary: new MediaLibrary(),
			getMediaLibrary: new MediaLibrary(),
			alert: new Alert(),
			IMAGES_EXT,
			ALL_EXT,
			validCountries,
			validCompanyTypes,
			selectedLanguage: process.env.VUE_APP_LANG,
			years: [],
			months: [],
			startDays: [],
			endDays: [],
			hours: [],
			minutes: [],
			selectedStartYear: '',
			selectedStartMonth: '',
			selectedStartDay: '',
			selectedStartHour: '',
			selectedStartMinute: '',
			selectedEndYear: '',
			selectedEndMonth: '',
			selectedEndDay: '',
			selectedEndHour: '',
			selectedEndMinute: '',
			YMDHMS_FORMAT,
			YM_FORMAT,
			TZN: TIMEZONE_NAME,
		};
	},
	computed: {
		loading() {
			return !!this.createMediaLibrary.data.loading;
		},
		errors() {
			try {
				return this.createMediaLibrary.data.errors.errors;
			} catch (error) {
				return [];
			}
		},
		media() {
			try {
				const { data } = this.getMediaLibrary.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		categories() {
			const categoriesDropdown = [{ value: '', text: this.translate('select_category') }];
			CATEGORIES.forEach((element) => {
				categoriesDropdown.push({ value: element, text: this.translate(element) });
			});
			return categoriesDropdown;
		},
		isAdminOrCorporateWithCountry() {
			return this.superAdmin.includes(this.$user.details().type) || (this.corporate.includes(this.$user.details().type) && !this.$user.details().country);
		},
		start_date() {
			if (this.selectedStartYear !== '' && this.selectedStartMonth !== '' && this.selectedStartDay !== '' && this.selectedStartHour !== '' && this.selectedStartMinute !== '') {
				return this.createDate(this.selectedStartYear, this.selectedStartMonth, this.selectedStartDay, this.selectedStartHour, this.selectedStartMinute);
			}
			return '';
		},
		end_date() {
			if (this.selectedEndYear !== '' && this.selectedEndMonth !== '' && this.selectedEndDay !== '' && this.selectedEndHour !== '' && this.selectedEndMinute !== '') {
				return this.createDate(this.selectedEndYear, this.selectedEndMonth, this.selectedEndDay, this.selectedEndHour, this.selectedEndMinute);
			}
			return '';
		},
	},
	watch: {
		'form.selector': function selector() {
			const select = this.form.selector;
			// no need to reassign 'select' if it's already equal to all of the countries
			if (this.handleSelectAll(select, 'countries') === true && select !== this.countriesOnly) {
				this.form.selector = this.countriesOnly;
			}
		},
		'form.company_types': function selector() {
			const select = this.form.company_types;
			// no need to reassign 'select' if it's already equal to all of the companies types
			if (this.handleSelectAll(select, 'company_types') === true && select !== this.companyTypesOnly) {
				this.form.company_types = this.companyTypesOnly;
			}
		},
		language() {
			try {
				this.initializeMultiselect();
				if (this.form.selector.length > 0) {
					this.form.selector = this.form.selector.map((item) => ({
						value: item.value,
						text: this.translate(item.value.toLowerCase()),
					}));
				}
				return this.form.selector;
			} catch (error) {
				return this.form.selector;
			}
		},
		start_date(newVal) {
			this.form.start_date = newVal;
			this.clearError('start_date');
		},
		end_date(newVal) {
			this.form.end_date = newVal;
			if (typeof newVal === 'undefined') {
				this.form.end_date = '';
			}
			this.clearError('end_date');
		},
	},
	created() {
		this.loadContent();
	},
	mounted() {
		this.populateTime();
		this.initializeLanguage();
		if (this.isAdminOrCorporateWithCountry) this.initializeMultiselect();
	},
	methods: {
		initializeLanguage() {
			const languageRegexp = /language=([^&]+)/;
			const language = window.location.href.match(languageRegexp);
			if (language !== null) {
				[this.selectedLanguage] = [language[1]];
			}
			this.form.language = this.selectedLanguage;
		},
		changeLanguage(language) {
			this.$route.query.language = language;
		},
		clearError(field) {
			if (field) {
				delete this.createMediaLibrary.data.errors.errors[field];
				this.createMediaLibrary.data.errors.errors = { ...this.errors };
			}
		},
		hasError(field) {
			if (typeof this.errors[field] !== 'undefined') {
				return true;
			}
			return false;
		},
		initializeMultiselect() {
			// Countries
			this.countries = this.validCountries.map((item) => ({
				value: item,
				text: this.translate(item.toLowerCase()),
			}));
			this.countriesOnly = [...this.countries];
			this.countries.unshift({ value: 'ALL', text: this.translate('all_countries') });

			// Companies
			this.companyTypes = this.validCompanyTypes.map((item) => ({
				value: item,
				text: this.translate(item.toLowerCase()),
			}));
			this.companyTypesOnly = [...this.companyTypes];
			this.companyTypes.unshift({ value: 'ALL', text: this.translate('all_company_types') });
		},
		// Dates
		populateTime() {
			const currentYear = this.$moment().year();
			for (let i = 0; i <= 59; i += 1) {
				if (i <= 20) this.years.push(currentYear + i);
				if (i < 10) {
					this.hours.push(`0${i}`);
					this.minutes.push(`0${i}`);
					if (i > 0) this.months.push(`0${i}`);
				} else {
					if (i <= 23) this.hours.push(`${i}`);
					if (i <= 12) this.months.push(`${i}`);
					this.minutes.push(`${i}`);
				}
			}
		},
		updateDays(id) {
			let year = '';
			let month = '';

			const days = [];
			if (id === 'start') {
				this.startDays = [];
				year = this.selectedStartYear;
				month = this.selectedStartMonth;
			} else if (id === 'end') {
				this.endDays = [];
				year = this.selectedEndYear;
				month = this.selectedEndMonth;
			}
			const maxDaysInMonth = this.$moment(`${year}-${month}`, this.YM_FORMAT).daysInMonth();
			for (let i = 1; i <= maxDaysInMonth; i += 1) {
				if (i < 10) {
					days.push(`0${i}`);
				} else {
					days.push(`${i}`);
				}
			}
			if (id === 'start') {
				this.startDays = days;
				this.selectedStartDay = '01';
			} else if (id === 'end') {
				this.endDays = days;
				this.selectedEndDay = '01';
			}
		},
		createDate(year, month, day, hour, minute) {
			if (year !== +'' && month !== +'' && day !== +'' && hour !== +'' && minute !== +'') {
				return this.$moment(`${year}-${month}-${day} ${hour}:${minute}:00.000000`).format(this.YMDHMS_FORMAT);
			}

			return '';
		},
		// Multiselect
		handleSelectAll(select, field) {
			let allSelected = false;
			if (typeof select[0] === 'undefined') return false;

			if (field === 'countries') {
				// 'this.countries' array can contain 'Select all option' object
				// 'this.countriesOnly' does not change and it never contains 'Select all option' object, only countries
				const selectedCountries = select.map((country) => country.value);
				const numberOfCountries = this.countriesOnly.length;
				const selectAllExists = (this.countries[0].value === 'ALL');
				// If:  1) 'select all' option is chosen		OR		2) if you chose all countries manually
				// delete 'select all' option (as all countries are already chosen)
				if (selectedCountries.includes('ALL') || selectedCountries.length === numberOfCountries) {
					if (selectAllExists) {
						this.countries.shift();
					}
					allSelected = true;
				}
				// If 1) 'selected all' option is deleted		2) not all countries are selected
				// then insert 'select all' into the dropdown options.
				if ((!selectAllExists) && (selectedCountries.length !== numberOfCountries)) {
					this.countries.unshift({ value: 'ALL', text: this.translate('all_countries') });
				}
			}

			if (field === 'company_types') {
				const selectedCompanyTypes = select.map((companyType) => companyType.value);
				const numberOfCompanyTypes = this.companyTypesOnly.length;
				const selectAllExists = (this.companyTypes[0].value === 'ALL');

				if (selectedCompanyTypes.includes('ALL') || selectedCompanyTypes.length === numberOfCompanyTypes) {
					if (selectAllExists) {
						this.companyTypes.shift();
					}
					allSelected = true;
				}
				if ((!selectAllExists) && (selectedCompanyTypes.length !== numberOfCompanyTypes)) {
					this.companyTypes.unshift({ value: 'ALL', text: this.translate('all_company_types') });
				}
			}
			return allSelected;
		},
		getCountriesIso(select) {
			const selectedIsoCodes = select.map((countryAttributes) => countryAttributes.value).toString();
			return selectedIsoCodes;
		},
		getTypes(select) {
			const selectedUserTypes = select.map((userTypes) => {
				if (userTypes.value === 'ALL') {
					return this.userTypes.map((userType) => userType.value);
				}
				return userTypes.value;
			}).toString();
			return selectedUserTypes;
		},
		typeChange() {
			this.form.url = '';
			this.form.file = '';
			this.clearError('file');
			this.clearError('url');
		},
		preparePayload() {
			const formData = new FormData();
			let [File] = this.$refs.thumbnail.$refs.input.files;
			this.form.thumbnail = File;
			[File] = this.$refs.mobile_thumbnail.$refs.input.files;
			this.form.mobile_thumbnail = File;
			if (this.form.type === 'file') {
				[File] = this.$refs.file.$refs.input.files;
				this.form.file = File;
				formData.set('file', this.form.file);
			} else if (this.form.type === 'url') {
				formData.set('url', this.form.url);
			}
			formData.set('name', this.form.name);
			formData.set('short_name', this.form.short_name);
			formData.set('category', this.form.category);
			formData.set('thumbnail', this.form.thumbnail);
			formData.set('mobile_thumbnail', this.form.mobile_thumbnail);
			formData.set('language_code', this.form.language);
			formData.set('tags', this.form.tags);
			if (this.form.start_date) {
				formData.set('start_date', this.form.start_date);
			} else {
				formData.set('start_date', this.$moment().tz(this.TZN).format(this.YMDHMS_FORMAT));
			}
			if (this.form.end_date) formData.set('end_date', this.form.end_date);

			formData.set('countries', this.isAdminOrCorporateWithCountry ? this.getCountriesIso(this.form.selector) : this.country);
			if (Object.keys(this.form.company_types).length) formData.set('company_types', this.getTypes(this.form.company_types));
			this.$route.query.language = this.form.language;
			this.createMedia(formData);
		},
		createMedia(formData) {
			this.createMediaLibrary.data.loading = true;
			this.createMediaLibrary.createMedia(formData).then(() => {
				this.alert.toast('success', this.translate('swal_success_upload_text'));
				this.$router.push({ name: 'MediaLibrary', query: this.$route.query });
			}).catch((errors) => {
				this.createMediaLibrary.data.errors.errors = errors.errors;
			}).finally(() => {
				this.createMediaLibrary.data.loading = false;
			});
		},
		loadContent() {
			this.types.push({ value: 'file', text: 'file' });
			this.types.push({ value: 'url', text: 'url' });
			this.languages = LANGUAGES.map((languageCode) => ({
				value: languageCode,
				text: `${languageCode}_language`,
			}));
			this.getMediaLibrary.getMediaLibrary().then(() => {
				this.form.category = this.categories[0].value;
			});
		},
		addTag(newTag) {
			this.form.tags.push(newTag);
		},
	},
};
</script>
<style scoped>
.custom-gutter > [class*='col-'] {
	padding-right:4px;
	padding-left:4px;
}
</style>
